import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ApiResponse } from '../../../models/ApiResponse';
import { SearchInput } from '../form-control/SearchInput';
import Accordion from '../accordion/Accordion';
import Checkbox from '../form-control/Checkbox';
import Loader from '../Loader';
import { InputStyle } from '../form-control/Input';
import ObjectUtils from '../../../utils/ObjectUtils';
import { useTranslation } from 'react-i18next';
import { FormListItem } from '../../../models/Form';
import StringUtils from '../../../utils/StringUtils';
import { DocumentListItem } from '../../../models/Document';
import LanguageUtils from '../../../utils/LanguageUtils';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { FormType } from '../../../models/FormTypes';
import Tooltip from '../Tooltip';

type SubItem = {
  id: string;
  title: string;
  version?: string;
  meta?: string;
};

type Item = {
  id: string;
  title: string;
  version: string;
  subItemCount: number;
};

type Props = {
  subItemType: 'reference' | 'attachment';
  loadSubItems: (id: string) => Promise<SubItem[]>;
  onItemStatusChange: (id: string, subItemId: string, status: boolean) => void;
  isLoading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  loadMore: () => void;
  pagedData: ApiResponse<FormListItem[]> | null;
};

const DataExportMetaSelection: FC<Props> = (props) => {
  const { onItemStatusChange, loadSubItems, subItemType, isLoading, searchValue, setSearchValue, loadMore, pagedData } = props;

  const [subItems, setSubItems] = useState<Record<string, SubItem[]>>({});
  const [excludedItems, setExcludedItems] = useState<Record<string, string[]>>({});

  const { t } = useTranslation('data-import-export');

  const [lastElRef] = useInfiniteScroll(loadMore, isLoading);

  const items = useMemo<Item[]>(() => {
    return (
      pagedData?.data.map((x) => {
        let title = x.subtitle || LanguageUtils.getTranslation('title', x.translations);
        if (x.type === FormType.Document) {
          const doc = x as DocumentListItem;
          title = `${StringUtils.makePrefixWithNumber(doc.prefix, doc.documentNumber, doc.templateModuleTranslations)}-${title}`;
        }

        return {
          id: x.id,
          title,
          version: `v${x.majorVersion}`,
          subItemCount: (subItemType === 'reference' ? x.referenceCount : x.attachmentCount) ?? 0,
        };
      }) ?? []
    );
  }, [pagedData?.data, subItemType]);

  const loadDetails = useCallback(
    (id: string) => {
      if (subItems[id]) {
        return Promise.resolve(subItems[id]);
      }

      return loadSubItems(id).then((si) => {
        setSubItems((prev) => ({ ...prev, [id]: si }));
        return si;
      });
    },
    [loadSubItems, subItems],
  );

  const onMainChecked = useCallback(
    (id: string, value: boolean) => {
      // Checking main item, so removing from excluded items
      if (value) {
        loadDetails(id).then((si) => {
          si.forEach((x) => onItemStatusChange(id, x.id, true));
          setExcludedItems((prev) => {
            const result = ObjectUtils.DeepClone(prev);
            if (!result[id]) result[id] = [];

            result[id] = result[id].filter((exclId) => !si.map((x) => x.id).includes(exclId));
            return result;
          });
        });
      }

      // Unchecking main item, so adding to excluded items
      else {
        loadDetails(id).then((si) => {
          si.forEach((x) => onItemStatusChange(id, x.id, false));
          setExcludedItems((prev) => {
            const result = ObjectUtils.DeepClone(prev);
            if (!result[id]) result[id] = [];

            result[id] = [...result[id], ...si.map((x) => x.id)];
            return result;
          });
        });
      }
    },
    [loadDetails, onItemStatusChange],
  );

  const onSubItemChecked = useCallback(
    (mainId: string, subId: string, value: boolean) => {
      onItemStatusChange(mainId, subId, value);

      if (value) {
        setExcludedItems((prev) => {
          const result = ObjectUtils.DeepClone(prev);
          if (!result[mainId]) result[mainId] = [];

          result[mainId] = result[mainId].filter((x) => x !== subId);
          return result;
        });
      } else {
        setExcludedItems((prev) => {
          const result = ObjectUtils.DeepClone(prev);
          if (!result[mainId]) result[mainId] = [];

          result[mainId] = [...result[mainId], subId];
          return result;
        });
      }
    },
    [onItemStatusChange],
  );

  useEffect(() => {
    // Auto load sub-items when there is only one main item
    if (items.length === 1 && items[0].subItemCount !== 0) {
      loadDetails(items[0].id);
    }
  }, [items, loadDetails]);

  return (
    <div>
      <div className="flex items-center justify-end border-b pb-4">
        <div className="w-[300px]">
          <SearchInput style={InputStyle.MINIMAL} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        </div>
      </div>
      {items.map((item, i) => {
        const checkValue = item.subItemCount > 0 && (!subItems[item.id] || subItems[item.id].some((x) => !excludedItems[item.id]?.includes(x.id)));
        const checkHalf = checkValue && subItems[item.id] && subItems[item.id].some((x) => excludedItems[item.id]?.includes(x.id));

        return (
          <div key={item.id} ref={i === items.length - 1 ? lastElRef : undefined}>
            <Accordion
              highlightedActiveStyle
              titleClassName="py-4"
              active={items.length === 1 && items[0].subItemCount !== 0}
              title={
                <>
                  <Tooltip text={item.title} truncatedTextMode>
                    {(tooltip) => (
                      <div {...tooltip} className="flex w-[70%] items-center gap-3 truncate">
                        <Checkbox
                          value={checkValue}
                          indeterminate={checkHalf}
                          onChange={(value) => onMainChecked(item.id, value)}
                          disabled={item.subItemCount === 0}
                        />
                        <span className="truncate">{item.title}</span>
                        <span className="text-dpm-12 text-black">{item.version}</span>
                      </div>
                    )}
                  </Tooltip>

                  <div className="w-64 flex-shrink-0 text-right">
                    {item.subItemCount === 0
                      ? t(`export.modal.${subItemType}.zero`)
                      : t(`export.modal.${subItemType}.selected`, {
                          count: item.subItemCount - (excludedItems[item.id]?.length ?? 0),
                          total: item.subItemCount,
                        })}
                  </div>
                </>
              }
              disabled={item.subItemCount === 0}
            >
              {subItems[item.id] ? (
                <div>
                  {subItems[item.id].map((subItem) => (
                    <div key={subItem.id} className="flex items-center gap-3">
                      <Checkbox
                        value={!excludedItems[item.id]?.includes(subItem.id)}
                        onChange={(value) => onSubItemChecked(item.id, subItem.id, value)}
                        label={
                          <div className="flex items-center gap-3">
                            <span>{subItem.title}</span>
                            <span className="text-dpm-12 text-black">{subItem.version}</span>
                            <span className="text-dpm-12 text-accent-1">{subItem.meta}</span>
                          </div>
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="relative mt-2 min-h-8">
                  <Loader centered size={8} />
                </div>
              )}
            </Accordion>
          </div>
        );
      })}
      {isLoading && (
        <div className="relative min-h-8">
          <Loader centered size={8} />
        </div>
      )}
    </div>
  );
};

export default DataExportMetaSelection;
